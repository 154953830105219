<template>
	<v-sheet class="asset" id="asset" style="height: calc(100vh - 88px)">
		<CreateDialog :dialog="createDialog" v-on:close="createDialog = false">
			<template v-slot:title>
				<span>{{ pageTitle() }} </span>
				<span class="orange--text text--darken-4 barcode-font-size">#{{ equipment.barcode }}</span>
			</template>
			<template v-slot:body>
				<template v-if="!barcodeLoading">
					<v-row>
						<v-col md="12">
							<v-stepper flat tile class="item-group-stepper" v-model="stepper">
								<v-stepper-header>
									<v-stepper-step
										class="py-2 btx-label"
										color="blue darken-4"
										:complete="stepper > 1"
										step="1"
									>
										Overview
									</v-stepper-step>
									<v-divider></v-divider>
									<v-stepper-step
										color="blue darken-4"
										v-if="equipment.has_warranty"
										class="py-2 btx-label"
										:complete="stepper > 2"
										step="2"
									>
										Warranty Details
									</v-stepper-step>
								</v-stepper-header>
								<v-stepper-items>
									<v-form
										ref="equipmentForm"
										v-model.trim="formValid"
										lazy-validation
										v-on:submit.stop.prevent="updateOrCreate"
									>
										<v-stepper-content class="pt-0" step="1">
											<v-row>
												<v-col md="8">
													<perfect-scrollbar
														:options="{ suppressScrollX: true }"
														class="scroll custom-box-top-inner-shadow"
														style="max-height: calc(100vh - 220px); position: relative"
													>
														<v-row class="px-4">
															<v-col md="3" class="mt-2 py-0">
																<label for="name" class="btx-label mt-2 required">Name</label>
															</v-col>
															<v-col md="9" class="py-0">
																<TextInput
																	:counter="150"
																	:disabled="pageLoading"
																	:loading="pageLoading"
																	id="name"
																	:rules="[vrules.required(equipment.name, 'Name')]"
																	:class="{
																		required: !equipment.name,
																	}"
																	placeholder="Name"
																	v-model="equipment.name"
																></TextInput>
															</v-col>
															<template v-if="false">
																<v-col md="3" class="my-auto py-0">
																	<label for="name" class="btx-label mt-2">Barcode</label>
																</v-col>
																<v-col md="9" class="py-0">
																	<TextInput
																		hide-details
																		:disabled="pageLoading"
																		:loading="pageLoading"
																		id="barcode"
																		placeholder="Barcode"
																		v-model="equipment.barcode"
																	>
																	</TextInput>
																</v-col>
															</template>
															<v-col md="3" class="my-auto py-0">
																<label for="brand" class="btx-label mt-2 required">Brand</label>
															</v-col>
															<v-col md="9" class="py-0">
																<AutoCompleteInput
																	hide-details
																	:items="brandList"
																	:disabled="pageLoading"
																	:loading="pageLoading"
																	id="brand"
																	:rules="[vrules.required(equipment.brand, 'Brand')]"
																	:class="{
																		required: !equipment.brand,
																	}"
																	placeholder="Brand"
																	v-model="equipment.brand"
																	append-outer-icon="mdi-cog"
																	v-on:click:append-outer="manageBrandDialog = true"
																>
																</AutoCompleteInput>
															</v-col>

															<!-- <v-col md="3" class="my-auto py-0">
																<label for="e-password" class="btx-label mt-2">Password</label>
															</v-col>
															<v-col md="9" class="py-0">
																<TextInput
																	hide-details
																	:disabled="pageLoading"
																	:loading="pageLoading"
																	id="e-password"
																	placeholder="Password"
																	v-model="equipment.password"
																>
																</TextInput>
															</v-col> -->

															<v-col md="3" class="my-auto py-0">
																<label for="category" class="btx-label mt-2 required">Equipment Category</label>
															</v-col>
															<v-col md="9" class="py-0">
																<!-- :rules="[vrules.required(equipment.category, 'Category')]"
																	:class="{
																		required: !equipment.category,
																	}" -->
																<AutoCompleteInput
																	hide-details
																	:items="categoryList"
																	:disabled="pageLoading"
																	:loading="pageLoading"
																	id="category"
																	placeholder="Category"
																	v-model="equipment.category"
																	append-outer-icon="mdi-cog"
																	v-on:click:append-outer="manageCategoryDialog = true"
																>
																</AutoCompleteInput>
															</v-col>
															<v-col md="3" class="my-auto py-0">
																<label for="mnufacturer" class="btx-label mt-2">Manufacturer</label>
															</v-col>
															<v-col md="9" class="py-0">
																<SelectManufacturer
																	hide-details
																	:disabled="pageLoading"
																	:loading="pageLoading"
																	id="mnufacturer"
																	placeholder="Manufacturer"
																	v-model="equipment.manufacturer"
																	append-outer-icon="mdi-cog"
																></SelectManufacturer>
															</v-col>
															<v-col md="3" class="py-0">
																<label for="description" class="btx-label mt-2 required">Description</label>
															</v-col>
															<v-col md="9" class="py-0">
																<TextAreaInput
																	:counter="250"
																	:disabled="pageLoading"
																	:loading="pageLoading"
																	id="description"
																	placeholder="Description"
																	v-model="equipment.description"
																	:rules="[vrules.required(equipment.description, 'Description')]"
																	:class="{
																		required: !equipment.description,
																	}"
																></TextAreaInput>
															</v-col>

															<v-col md="3" class="my-auto py-0">
																<label for="tags" class="btx-label mt-4">Tags</label>
															</v-col>
															<v-col md="9" class="py-0 mt-3">
																<v-combobox
																	id="tags"
																	hide-details
																	v-model="equipment.tags"
																	class="combobox"
																	:filter="filterTag"
																	:hide-no-data="!search"
																	outlined
																	:items="items"
																	:search-input.sync="search"
																	item-text="tag"
																	item-value="id"
																	hide-selected
																	multiple
																	small-chips
																	dense
																	placeholder="Search Tag"
																>
																	<template v-slot:no-data>
																		<v-chip label class="my-2 mx-2" small>
																			{{ search }}
																		</v-chip>
																	</template>
																	<template v-slot:selection="{ attrs, item, parent, selected }">
																		<v-chip
																			class="my-1"
																			v-if="item === Object(item)"
																			v-bind="attrs"
																			:input-value="selected"
																			label
																			small
																		>
																			<span class="pr-2">
																				{{ item.tag }}
																			</span>
																			<v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
																		</v-chip>
																	</template>
																	<v-list-item class="my-2 white" v-slot:item="{ item }">
																		<v-list-item-content>
																			<v-list-item-title>
																				<v-chip class="mb-1" label small>
																					{{ item.tag }}
																				</v-chip>
																			</v-list-item-title>
																		</v-list-item-content>
																	</v-list-item>
																</v-combobox>
															</v-col>

															<v-col md="3" class="my-auto py-0">
																<label for="contact" class="btx-label mt-2">Warranty</label>
															</v-col>
															<v-col md="9">
																<v-checkbox
																	dense
																	v-model="equipment.has_warranty"
																	color="blue darken-4"
																	hide-details
																	class="mt-0 mb-0"
																></v-checkbox>
															</v-col>
														</v-row>
														<v-row>
															<v-col md="12" class="py-0">
																<p class="middle-header-background my-4 mt-7 px-4">
																	Purchased Information<br />
																	<span>
																		<v-icon small>mdi-progress-question</v-icon> Specify the purchase and location
																		details for your asset
																	</span>
																</p>
															</v-col>
														</v-row>
														<v-row class="px-4">
															<v-col md="3" class="my-auto py-0">
																<label for="initial-stock" class="btx-label mt-2">Initial Stock</label>
															</v-col>
															<v-col md="9" class="py-0">
																<QuantityInput
																	hide-details
																	:disabled="pageLoading"
																	:loading="pageLoading"
																	id="initial-stock"
																	placeholder="Initial Stock"
																	v-model="equipment.initial_stock"
																></QuantityInput>
															</v-col>
															<v-col md="3" class="my-auto py-0">
																<label for="cost-price" class="btx-label mt-2 required">Cost Price</label>
															</v-col>
															<v-col md="9" class="py-0">
																<PriceInput
																	hide-details
																	:disabled="pageLoading"
																	:loading="pageLoading"
																	id="cost-price"
																	placeholder="Cost Price"
																	v-model="equipment.cost_price"
																	:rules="[vrules.required(equipment.cost_price, 'Cost Price')]"
																	:class="{
																		required: !equipment.cost_price,
																	}"
																></PriceInput>
															</v-col>
															<v-col md="3" class="my-auto py-0">
																<label for="vendor" class="btx-label mt-2 required">Supplier</label>
															</v-col>
															<v-col md="9" class="py-0">
																<AutoCompleteInput
																	hide-details
																	:items="vendorList"
																	:disabled="pageLoading"
																	:loading="pageLoading"
																	id="supplier"
																	placeholder="Supplier"
																	v-model="equipment.supplier"
																	:rules="[vrules.required(equipment.supplier, 'Supplier')]"
																	:class="{
																		required: !equipment.supplier,
																	}"
																	append-outer-icon="mdi-cog"
																	v-on:click:append-outer="manageSupplierDialog = true"
																>
																</AutoCompleteInput>
															</v-col>
															<v-col md="3" class="my-auto py-0">
																<label for="purchased-on" class="btx-label mt-2 required">Purchased On</label>
															</v-col>
															<v-col md="9" class="py-0">
																<DatePicker
																	hide-details
																	clearable
																	:disabled="pageLoading"
																	:loading="pageLoading"
																	id="purchased-on"
																	placeholder="Purchased On"
																	v-model="equipment.purchased_on"
																	:rules="[vrules.required(equipment.purchased_on, 'Purchased On')]"
																	:class="{
																		required: !equipment.purchased_on,
																	}"
																></DatePicker>
															</v-col>
															<v-col md="3" class="mt-2 py-0">
																<label for="attachment" class="btx-label mt-2">Attachment</label>
															</v-col>
															<v-col md="9" class="py-0">
																<FileUpload v-model="attachments" :max-limit="3"> </FileUpload>
																<template v-if="false">
																	<template v-for="(data, index) in btx_attachments">
																		<v-row :key="`file-row-${index}`" :class="{ 'table-alternate-row': index % 2 }">
																			<v-col md="5" class="py-0">
																				<v-file-input
																					v-model="data.file"
																					placeholder="Click here to select files"
																					color="blue darken-4"
																					multiple
																					outlined
																					class="mt-3"
																					prepend-icon=""
																					prepend-inner-icon="mdi-attachment"
																					hide-details
																					v-on:change="limitAttachment($event, index)"
																				>
																				</v-file-input>
																			</v-col>
																			<v-col md="5" class="py-0">
																				<TextInput hide-details placeholder="File Name" v-model="data.name"></TextInput>
																			</v-col>
																			<v-col md="1" class="py-0" v-if="index>0">
																				<v-btn
																					:disabled="btx_attachments.length < 2"
																					v-on:click="removeFile(index)"
																					class="white--text mt-3"
																					depressed
																					color="red darken-4"
																					tile
																					style="margin-left: -10px"
																					
																				>
																					<v-icon>mdi-delete</v-icon>
																				</v-btn>
																			</v-col>
																			<v-col md="1" class="py-0">
																				<v-btn
																					:disabled="btx_attachments.length > 2"
																					v-on:click="addMore()"
																					class="white--text mt-3"
																					depressed
																					color="blue darken-4"
																					tile
																					style="margin-left: -13px"
																				>
																					<v-icon>mdi-plus</v-icon>
																				</v-btn>
																			</v-col>
																		</v-row>
																	</template>
																</template>
															</v-col>

															<v-col md="3" class="py-0">
																<label for="remark" class="btx-label mt-2">Remark</label>
															</v-col>
															<v-col md="9" class="py-0">
																<TextAreaInput
																	hide-details
																	:disabled="pageLoading"
																	:loading="pageLoading"
																	id="remark"
																	placeholder="Remark"
																	v-model="equipment.remark"
																></TextAreaInput>
															</v-col>
															<v-col md="12" class="min-height-40"> </v-col>
														</v-row>
													</perfect-scrollbar>
												</v-col>
												<v-col md="4">
													<v-row>
														<v-col md="12" class="my-6 py-0 text-center">
															<ImageUpload
																can-change
																v-model="equipment.image"
																:page-loading="pageLoading"
															></ImageUpload>
														</v-col>
													</v-row>
												</v-col>
											</v-row>
										</v-stepper-content>
										<v-stepper-content class="pt-0" step="2" v-if="equipment.has_warranty">
											<v-row>
												<v-col md="9">
													<perfect-scrollbar
														:options="{ suppressScrollX: true }"
														class="scroll custom-box-top-inner-shadow"
														style="max-height: calc(100vh - 220px); position: relative"
													>
														<v-row>
															<v-col md="12" class="py-0">
																<p class="middle-header-background my-4 mt-7 px-4">
																	Warranty Information<br />
																	<span>
																		<v-icon small>mdi-progress-question</v-icon> Specify the warranty details for your
																		equipment
																	</span>
																</p>
															</v-col>
														</v-row>
														<v-row class="px-4">
															<v-col md="3" class="my-auto py-0">
																<label :for="`service-from-${uniqFieldId}`" class="btx-label mt-3 required"
																	>Warranty Period</label
																>
															</v-col>
															<v-col md="9" class="py-0">
																<v-layout>
																	<v-flex md6>
																		<NumberInput
																			hide-details
																			:disabled="pageLoading"
																			:loading="pageLoading"
																			:id="`checkout-duration-${uniqFieldId}`"
																			placeholder="Duration"
																			v-model="equipment.warranty_duration"
																			v-on:keyup="(e) => updateWarrantyDuration(e, 'warranty_duration')"
																		></NumberInput>
																	</v-flex>
																	<v-flex md6>
																		<SelectInput
																			hide-details
																			:items="warrantyDurationList"
																			:disabled="pageLoading"
																			:loading="pageLoading"
																			placeholder="Duration"
																			v-model="equipment.warranty_duration_type"
																			v-on:change="(e) => updateWarrantyDuration(e, 'warranty_duration_type')"
																		></SelectInput>
																	</v-flex>
																</v-layout>
															</v-col>

															<v-col md="3" class="my-auto py-0">
																<label for="cost-price" class="btx-label mt-2 required">Start Date</label>
															</v-col>
															<v-col md="9" class="py-0">
																<DatePicker
																	hide-details
																	clearable
																	:disabled="pageLoading"
																	:loading="pageLoading"
																	id="warranty-start-date"
																	placeholder="Start Date"
																	v-model="equipment.warranty_start_date"
																	v-on:change="(e) => updateWarrantyDuration(e, 'start_date')"
																	:rules="[vrules.required(equipment.warranty_start_date, 'Warranty Start Date')]"
																	:class="{
																		required: !equipment.warranty_start_date,
																	}"
																></DatePicker>
															</v-col>
															<v-col md="3" class="my-auto py-0">
																<label for="vendor" class="btx-label mt-2 required">End Date</label>
															</v-col>
															<v-col md="9" class="py-0">
																<DatePicker
																	hide-details
																	clearable
																	:disabled="pageLoading"
																	:loading="pageLoading"
																	id="warranty-end-date"
																	placeholder="End Date"
																	:min-date="equipment.warranty_start_date"
																	v-model="equipment.warranty_end_date"
																	:rules="[vrules.required(equipment.warranty_end_date, 'Warranty End Date')]"
																	:class="{
																		required: !equipment.warranty_end_date,
																	}"
																	v-on:change="checkWarrantyDiff"
																></DatePicker>
															</v-col>
															
															<!--Reminder code was here-->

															<!--Reminder new-->
															<v-col md="3" class="my-auto py-0">
																<label for="reminder-type" class="btx-label mt-4 required">Reminder Type</label>
															</v-col>
															<v-col md="9" class="py-0">
																<AutoCompleteInput
																	hide-details
																	:items="reminder_type_items"
																	:disabled="pageLoading"
																	item-text="text"
																	item-value="value"
																	@change="reminderTypeChange"
																	:loading="pageLoading"
																	id="reminder-type"
																	placeholder="reminder"
																	v-model="reminder.type"
																	:rules="[vrules.required(reminder.type, 'Reminder Type')]"
																	:class="{
																		required: !reminder.type,
																	}"
																>
																</AutoCompleteInput>
															</v-col>
															<template v-if="reminder.type != 1">
																<v-col md="3" class="my-auto py-0">
																	<label for="frequency" class="btx-label mt-4 required">Frequency</label>
																</v-col>
																<v-col md="9" class="py-0">
																	<TextInput
																		type="number"
																		hide-details
																		:disabled="pageLoading"
																		:loading="pageLoading"
																		id="frequency"
																		@keyup="frequencyChange"
																		placeholder="Frequency"
																		v-model.number="reminder.frequency"
																		:rules="[vrules.required(reminder.frequency, 'Reminder frequency')]"
																		:class="{
																			required: !reminder.frequency,
																		}"
																	></TextInput>
																</v-col>
															</template>
															<v-col md="3" class="my-auto py-0">
																<label for="reminder_start_date" class="btx-label mt-4 required">Reminder Start Date</label>
															</v-col>
															<v-col md="3" class="py-0">
																<DatePicker
																	hide-details
																	:disabled="pageLoading"
																	:loading="pageLoading"
																	@change="reminderTypeChange"
																	id="reminder_start_date"
																	v-model="reminder.start_date"
																	:rules="[vrules.required(reminder.start_date, 'Reminder start_date')]"
																	:class="{
																		required: !reminder.start_date,
																	}"
																>
																</DatePicker>
															</v-col>
															<template v-if="reminder.type != 1">
																<v-col md="3" class="my-auto py-0 text-right">
																	<label for="reminder_end_date" class="btx-label mt-4">Reminder End Date</label>
																</v-col>
																<v-col md="3" class="py-0">
																	<DatePicker
																		hide-details
																		:disabled="pageLoading"
																		:loading="pageLoading"
																		id="reminder_end_date"
																		v-model="reminder_end_date"
																	>
																	</DatePicker>
																</v-col>
															</template>
															<template v-else>
																<v-col md="6"></v-col>
															</template>
															<!--Reminder new end -->

															<v-col md="3" class="my-auto py-0">
																<label for="warranty-cost" class="btx-label mt-2">Cost</label>
															</v-col>
															<v-col md="9" class="py-0">
																<PriceInput
																	hide-details
																	:disabled="pageLoading"
																	:loading="pageLoading"
																	id="warranty-cost"
																	placeholder="Warranty Cost"
																	v-model="equipment.warranty_cost"
																></PriceInput>
															</v-col>
															<v-col md="3" class="mt-2 py-0">
																<label for="attachment" class="btx-label mt-2">Attachment</label>
															</v-col>
															<v-col md="9" class="py-0">
																<FileUpload v-model="wt_attachments" :max-limit="3"> </FileUpload>
																<template v-if="false">
																	<template v-for="(data, index) in wtx_attachments">
																		<v-row :key="`file-row-${index}`" :class="{ 'table-alternate-row': index % 2 }">
																			<v-col md="5" class="py-0">
																				<v-file-input
																					v-model="data.file"
																					placeholder="Click here to select files"
																					color="blue darken-4"
																					multiple
																					outlined
																					class="mt-3"
																					prepend-icon=""
																					prepend-inner-icon="mdi-attachment"
																					hide-details
																					v-on:change="limitAttachmentWarranty($event, index)"
																				>
																				</v-file-input>
																			</v-col>
																			<v-col md="5" class="py-0">
																				<TextInput hide-details placeholder="File Name" v-model="data.name"></TextInput>
																			</v-col>
																			<v-col md="1" class="py-0">
																				<v-btn
																					:disabled="wtx_attachments.length < 2"
																					v-on:click="removeFileWarranty(index)"
																					class="white--text mt-3"
																					depressed
																					color="red darken-4"
																					tile
																					style="margin-left: -10px"
																				>
																					<v-icon>mdi-delete</v-icon>
																				</v-btn>
																			</v-col>
																			<v-col md="1" class="py-0">
																				<v-btn
																					:disabled="wtx_attachments.length > 2"
																					v-on:click="addMoreWarranty()"
																					class="white--text mt-3"
																					depressed
																					color="blue darken-4"
																					tile
																					style="margin-left: -13px"
																				>
																					<v-icon>mdi-plus</v-icon>
																				</v-btn>
																			</v-col>
																		</v-row>
																	</template>
																</template>
															</v-col>
															<v-col md="3" class="py-0">
																<label for="remark" class="btx-label mt-2">Remark</label>
															</v-col>
															<v-col md="9" class="py-0">
																<TextAreaInput
																	hide-details
																	:disabled="pageLoading"
																	:loading="pageLoading"
																	id="remark"
																	placeholder="Remark"
																	v-model="equipment.warranty_remark"
																></TextAreaInput>
															</v-col>
															<v-col md="12" class="min-height-40"> </v-col>
														</v-row>
													</perfect-scrollbar>
												</v-col>
											</v-row>
										</v-stepper-content>
									</v-form>
								</v-stepper-items>
							</v-stepper>
						</v-col>
					</v-row>
				</template>
				<template v-else>
					<div class="mx-20 my-40 text-center">
						<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
					</div>
				</template>
			</template>
			<template v-slot:action>
				<v-row>
					<v-col md="12" class="text-right">
						<v-btn
							v-if="stepper > 1"
							:loading="pageLoading"
							class="white--text mr-2"
							depressed
							color="blue darken-4"
							tile
							:disabled="pageLoading"
							v-on:click="goBackStepperAsset()"
						>
							Back
						</v-btn>
						<v-btn
							:loading="pageLoading"
							class="white--text mr-2"
							depressed
							color="blue darken-4"
							tile
							v-on:click="updateOrCreate()"
						>
							Save
							<template v-if="equipment.has_warranty && stepper < 2">&amp; Next</template>
						</v-btn>
						<!-- <v-btn :disabled="pageLoading" depressed tile v-on:click="goBack()"> Cancel </v-btn> -->
					</v-col>
				</v-row>
			</template>
		</CreateDialog>
		<template v-if="manageCategoryDialog">
			<ManageCategory
				:dialog="manageCategoryDialog"
				:category="categoryList"
				v-on:close-dialog="manageCategoryDialog = false"
				v-on:success="getCategories"
			></ManageCategory>
		</template>
		<template v-if="manageBrandDialog">
			<ManageBrand
				:dialog="manageBrandDialog"
				:category="brandList"
				v-on:close-dialog="manageBrandDialog = false"
				v-on:success="getBrands"
			></ManageBrand>
		</template>
		<ManageSupplier
			:dialog="manageSupplierDialog"
			:category="categoryList"
			v-on:close-dialog="manageSupplierDialog = false"
			v-on:success="getCategories"
		></ManageSupplier>
	</v-sheet>
</template>
<script>
import { mapGetters } from "vuex";
import CreateDialog from "@/view/components/CreateDialog";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";

import { CreateEquipment, UpdateEquipment, GetEquipment } from "@/core/lib/equipment.lib";

import { UploadFiles } from "@/core/lib/upload.lib";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TextInput from "@/view/components/TextInput";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import FileUpload from "@/view/components/FileUpload";

import PriceInput from "@/view/components/PriceInput";
import DatePicker from "@/view/components/DatePicker";
import ImageUpload from "@/view/components/ImageUpload";
import SelectManufacturer from "@/view/components/SelectManufacturer";
import QuantityInput from "@/view/components/QuantityInput";
import { toString, toSafeInteger, map } from "lodash";
import ManageCategory from "@/view/components/Manage-Category.vue";
import ManageBrand from "@/view/components/Manage-Brand.vue";
import ManageSupplier from "@/view/components/Manage-Supplier.vue";
import ObjectPath from "object-path";
import MomentJS from "moment-timezone";
import ApiService from "@/core/services/api.service";
import NumberInput from "@/view/components/NumberInput";
import SelectInput from "@/view/components/SelectInput";
// import MomentJS from "moment-timezone";

export default {
	name: "equipment-create",
	title: "Create Equipments",
	data() {
		return {
			createDialog: true,
			currentDate: MomentJS().format("YYYY-MM-DD"),
			uuid: null,
			barcodeLoading: false,
			stepper: 1,
			duplicateUUID: null,
			barcode: null,
			manageCategoryDialog: false,
			manageBrandDialog: false,
			formValid: true,
			pageLoading: false,
			attachment: [],
			categoryList: [],
			brandList: [],
			vendorList: [],
			wt_attachments: [],
			attachments: [],
			manageSupplierDialog: false,
			reminder_type_items: [],
			reminder_end_date: null,
			manintaenaceReminder_end_date: null,
			reminder: {
				type: null,
				start_date: MomentJS().format("YYYY-MM-DD"),
				frequency: null,
				end_date: null,
				total: null,
			},
			manintaenaceReminder: {
				type: 1,
				start_date: null,
				frequency: null,
				end_date: null,
				total: null,
			},

			equipment: {
				id: 0,
				name: null,
				euqipment_barcode: null,
				brand: null,
				password: null,
				description: null,
				tags: [],
				has_warranty: false,
				initial_stock: null,
				cost_price: null,
				supplier: null,
				purchased_on: null,
				remark: null,
				warranty_duration: 0,
				warranty_duration_type: "day",
				warranty_start_date: null,
				warranty_end_date: null,
				warranty_remark: null,
				warranty_cost: null,
				warranty_reminder_date: null,
				image: null,
			},

			btx_attachments: [
				{
					file: null,
					url: null,
					name: null,
				},
			],
			wtx_attachments: [
				{
					file: null,
					url: null,
					name: null,
				},
			],

			search: null,
			items: [],
		};
	},
	components: {
		CreateDialog,
		TextInput,

		PriceInput,
		ImageUpload,
		SelectManufacturer,
		QuantityInput,
		DatePicker,
		TextAreaInput,
		FileUpload,
		ManageCategory,
		ManageBrand,
		AutoCompleteInput,
		ManageSupplier,
		NumberInput,
		SelectInput,
	},
	methods: {
		reminderTypeChange() {
			
			let start_date = MomentJS(this.reminder.start_date);
			this.reminder.frequency = this.reminder.frequency ? this.reminder.frequency : 1;
			if (this.reminder.type === 1) {
				this.reminder.frequency = null;
				this.reminder_end_date = null;
			} else if (this.reminder.type === 2) {
				this.reminder.frequency = 1;
				const days = start_date.add(this.reminder.frequency, "days");
				this.reminder_end_date = days.format("YYYY-MM-DD");
			} else if (this.reminder.type === 3) {
				// this.reminder.frequency = 1;
				const days = start_date.add(this.reminder.frequency * 7, "days");
				this.reminder_end_date = days.format("YYYY-MM-DD");
			} else if (this.reminder.type === 4) {
				const days = start_date.add(this.reminder.frequency, "months");
				this.reminder_end_date = days.format("YYYY-MM-DD");
				// this.task.frequency = 1;
			} else if (this.reminder.type === 5) {
				const days = start_date.add(this.reminder.frequency * 3, "months");
				this.reminder_end_date = days.format("YYYY-MM-DD");
				// this.reminder.frequency = 1;
			} else if (this.reminder.type === 6) {
				const days = start_date.add(this.reminder.frequency * 6, "months");
				this.reminder_end_date = days.format("YYYY-MM-DD");
				// this.task.frequency = 1;
			}
		},
		frequencyChange() {
			let start_date = MomentJS(this.reminder.start_date);
			if (this.reminder.type === 1) {
				this.reminder_end_date = null;
			} else if (this.reminder.type === 2) {
				const days = start_date.add(this.reminder.frequency, "days");
				this.reminder_end_date = days.format("YYYY-MM-DD");
			} else if (this.reminder.type === 3) {
				const days = start_date.add(this.reminder.frequency * 7, "days");
				this.reminder_end_date = days.format("YYYY-MM-DD");
			} else if (this.reminder.type === 4) {
				const days = start_date.add(this.reminder.frequency, "months");
				this.reminder_end_date = days.format("YYYY-MM-DD");
			} else if (this.reminder.type === 5) {
				const days = start_date.add(this.reminder.frequency * 3, "months");
				this.reminder_end_date = days.format("YYYY-MM-DD");
			} else if (this.reminder.type === 6) {
				const days = start_date.add(this.reminder.frequency * 6, "months");
				this.reminder_end_date = days.format("YYYY-MM-DD");
			}
		},
		pageTitle() {
			if (this.uuid) {
				return "Update Equipment";
			}
			return "Create Equipment";
		},
		addMore() {
			this.btx_attachments.push({
				file: null,
				url: null,
				name: null,
			});
		},
		checkWarrantyDiff(endDate) {
			if (this.equipment.warranty_start_date && endDate) {
				let startDate = MomentJS(this.equipment.warranty_start_date);
				let endData = MomentJS(endDate);
				let diffData = endData.diff(startDate, "days");
				this.equipment.warranty_duration_type = "day";
				this.equipment.warranty_duration = Number(diffData);
			}
		},
		updateWarrantyDuration(e, type) {
			
			let warranty_start_date = e;
			if (type != "start_date") {
				warranty_start_date =
					this.equipment && this.equipment.warranty_start_date
						? this.equipment.warranty_start_date
						: null;
			}
			
			if (warranty_start_date) {
				let { warranty_duration_type, warranty_duration } = this.equipment;
				// console.log(warranty_duration_type);
				// console.log(warranty_duration);
				let date = MomentJS(warranty_start_date, "YYYY-MM-DD");
				let duration = warranty_duration ? toSafeInteger(warranty_duration) : 0;
				
				switch (warranty_duration_type) {
					case "day":
						date = MomentJS(warranty_start_date, "YYYY-MM-DD").add(duration, "d");
						break;
					case "month":
						date = MomentJS(warranty_start_date, "YYYY-MM-DD").add(duration, "M");
						break;
					case "year":
						date = MomentJS(warranty_start_date, "YYYY-MM-DD").add(duration, "y");
						break;
				}
				
				/* this.$nextTick(() => {
					this.asset.warranty_end_date = date.format("YYYY-MM-DD");
				}); */
				this.equipment.warranty_end_date = date.format("YYYY-MM-DD");
				
			}
		},

		removeFile(index) {
			this.btx_attachments.splice(index, 1);
			if (this.btx_attachments.length < 1) {
				this.addMore();
			}
		},
		limitAttachment(output, index) {
			//console.log(output, "hello");
			if (this.pageLoading) {
				return false;
			}

			const attachment = output.slice(0, 3);
			//console.log(attachment);
			if (attachment.length <= 0) {
				console.log(attachment.length);
				return false;
			}

			const request = new FormData();

			for (let i = 0; i < attachment.length; i++) {
				request.append(`files[${i}]`, attachment[i]);
			}

			this.pageLoading = true;

			UploadFiles(request)
				.then((response) => {
					this.btx_attachments[index].url = ObjectPath.get(response, "0.path", null);
					this.btx_attachments[index].name = ObjectPath.get(response, "0.name", null);
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		addMoreWarranty() {
			this.wtx_attachments.push({
				file: null,
				url: null,
				name: null,
			});
		},
		removeFileWarranty(index) {
			this.wtx_attachments.splice(index, 1);
			if (this.wtx_attachments.length < 1) {
				this.addMoreWarranty();
			}
		},
		limitAttachmentWarranty(output, index) {
			if (this.pageLoading) {
				return false;
			}

			const attachment = output.slice(0, 3);

			if (attachment.length <= 0) {
				return false;
			}

			const request = new FormData();

			for (let i = 0; i < attachment.length; i++) {
				request.append(`files[${i}]`, attachment[i]);
			}

			this.pageLoading = true;

			UploadFiles(request)
				.then((response) => {
					this.wtx_attachments[index].url = ObjectPath.get(response, "0.path", null);
					this.wtx_attachments[index].name = ObjectPath.get(response, "0.name", null);
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		async updateOrCreate() {
			const _this = this;

			if (this.equipment.has_warranty && this.stepper < 2) {
				_this.stepper++;
				return false;
			}
			const formErrors = _this.validateForm(_this.$refs.equipmentForm);

			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.equipmentForm.validate()) {
				return false;
			}

			const attachmenets = map(this.attachments, (row) => {
				return { ...row, file: null };
			});

			const warratyAttachments = map(this.wt_attachments, (row) => {
				return { ...row, file: null };
			});

			const formData = {
				name: _this.equipment.name,
				barcode: _this.equipment.barcode,
				brand: _this.equipment.brand,
				password: _this.equipment.password,
				category: _this.equipment.category,
				manufacturer: _this.equipment.manufacturer,
				description: _this.equipment.description,
				tags: _this.equipment.tags,
				initial_stock: _this.equipment.initial_stock,
				cost_price: _this.equipment.cost_price,
				supplier: _this.equipment.supplier,
				purchased_on: _this.equipment.purchased_on,
				remark: _this.equipment.remark,
				has_warranty: _this.equipment.has_warranty,

				warranty_duration: _this.equipment.warranty_duration,
				warranty_duration_type: _this.equipment.warranty_duration_type,
				warranty_start_date: _this.equipment.warranty_start_date,
				warranty_end_date: _this.equipment.warranty_end_date,
				warranty_reminder_type: _this.reminder.type,

				warranty_reminder_date: _this.reminder.start_date,
				warranty_reminder_endDate: _this.reminder_end_date,
				warranty_reminder_frequency: _this.reminder.frequency,
				warranty_cost: _this.equipment.warranty_cost,
				warranty_remark: _this.equipment.warranty_remark,
				image: _this.equipment.image,
				attachment: attachmenets,
				attachment_warranty: warratyAttachments,
			};

			try {
				if(formData.has_warranty){
					if(formData.warranty_reminder_date>formData.warranty_reminder_endDate){
						_this.$store.commit(SET_ERROR, [{ model: true, message: "Reminder Start Date can not be greater than End Date" }]);
						return false;
					}
					if(!formData.warranty_duration){
						_this.$store.commit(SET_ERROR, [{ model: true, message: "Warranty period can not be 0" }]);
						return false;
					}
				}
				if(!formData.category){
					_this.$store.commit(SET_ERROR, [{ model: true, message: "Category is required" }]);
						return false;
				}
				
				_this.pageLoading = true;
				if (_this.uuid) {
					const { uuid } = await UpdateEquipment(_this.uuid, formData);

					_this.$router.replace({
						name: "equipment-detail",
						params: { uuid },
						query: { t: new Date().getTime() },
					});

					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Equipment has been updated." },
					]);
				} else {
					const { uuid } = await CreateEquipment(formData);

					_this.$router.replace({
						name: "equipment-detail",
						params: { uuid },
						query: { t: new Date().getTime() },
					});

					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Equipment has been created." },
					]);
				}
			} catch (error) {
				console.log(error);
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				_this.pageLoading = false;
			}
		},

		getTags() {
			const _this = this;
			ApiService.setHeader();
			ApiService.get("all-tags").then((res) => {
				_this.items = res.data;
			});
		},
		addMoreCheckout() {
			this.asset.checkout.ca_attachments.push({
				file: null,
				url: null,
				name: null,
			});
		},
		removeFileCheckout(index) {
			this.asset.checkout.ca_attachments.splice(index, 1);
			if (this.asset.checkout.ca_attachments.length < 1) {
				this.addMore();
			}
		},
		limitAttachmentCheckout(output, index) {
			if (this.pageLoading) {
				return false;
			}

			const attachment = output.slice(0, 3);

			if (attachment.length <= 0) {
				return false;
			}

			const request = new FormData();

			for (let i = 0; i < attachment.length; i++) {
				request.append(`files[${i}]`, attachment[i]);
			}

			this.pageLoading = true;

			UploadFiles(request)
				.then((response) => {
					this.asset.checkout.ca_attachments[index].url = ObjectPath.get(response, "0.path", null);
					this.asset.checkout.ca_attachments[index].name = ObjectPath.get(response, "0.name", null);
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},

		get_reminder_values() {
			let reminderParseDate = MomentJS(this.reminder.start_date);
			/* let test = MomentJS(reminderParseDate).add(6, 'M');
			console.log(test.format('YYYY-MM-DD')); */

			for (var i = 0; i < this.reminder.frequency; i++) {
				if (this.reminder.type == 6) {
					/* Half Yearly */
					reminderParseDate = MomentJS(reminderParseDate).add(6, "months");
				}

				if (this.reminder.type == 5) {
					/* Quarterly */
					reminderParseDate = MomentJS(reminderParseDate).add(3, "months");
				}

				if (this.reminder.type == 4) {
					/* Monthly */
					reminderParseDate = MomentJS(reminderParseDate).add(1, "months");
				}

				if (this.reminder.type == 3) {
					/* Weekly */
					reminderParseDate = MomentJS(reminderParseDate).add(7, "days");
				}

				if (this.reminder.type == 2) {
					/* Daily */
					reminderParseDate = MomentJS(reminderParseDate).add(1, "days");
				}

				if (this.reminder.type == 1) {
					/* One Time */
					reminderParseDate = MomentJS(reminderParseDate).add(7, "days");
				}
			}

			return (this.reminder_end_date = reminderParseDate.format("YYYY-MM-DD"));
		},
		goBackStepperAsset() {
			this.stepper--;
		},

		getEquipment() {
			GetEquipment(this.uuid)
				.then((data) => {
					this.barcode = data.barcode;
					this.equipment.barcode = data.barcode;
					this.equipment.name = data.name;
					this.equipment.brand = data.brand;
					this.equipment.category = data.category;
					this.equipment.password = data.password;
					this.equipment.manufacturer = data.manufacturer;
					this.equipment.description = data.description;
					this.equipment.tags = data.tags;
					this.equipment.has_warranty = data.has_warranty;
					this.equipment.initial_stock = data.initial_stock;
					this.equipment.cost_price = data.cost_price;
					this.equipment.supplier = data.supplier;
					this.equipment.purchased_on = data.purchased_on;
					this.equipment.remark = data.remark;

					this.equipment.image = data.image;

					(this.equipment.warranty_duration = data.warranty_duration),
						(this.equipment.warranty_duration_type = data.warranty_duration_type),
						(this.equipment.warranty_start_date = data.warranty_start_date),
						(this.equipment.warranty_end_date = data.warranty_end_date),
						(this.reminder.type = parseInt(data.warranty_reminder_type)),
						(this.reminder.start_date = data.warranty_reminder_date),
						(this.reminder_end_date = data.warranty_reminder_endDate),
						(this.reminder.frequency = data.warranty_reminder_frequency),
						(this.equipment.warranty_cost = data.warranty_cost);
					this.equipment.warranty_remark = data.warranty_remark;

					this.$store.dispatch(SET_BREADCRUMB, [
						{ text: "Equipment", disabled: true },
						{ text: "Update", disabled: true },
						{ text: this.barcode, disabled: true },
					]);
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		updateDuration() {
			let days = 1;

			let { duration_type, duration } = this.checkout;

			switch (duration_type) {
				case "day":
					days = toSafeInteger(duration);
					break;
				case "week":
					days = 7 * toSafeInteger(duration);
					break;
				case "month":
					days = 30 * toSafeInteger(duration);
					break;
			}

			this.$nextTick(() => {
				const date = MomentJS().add(days, "days");
				this.checkout.return_on = this.formatDateTimeRaw(date);
			});
		},
		getDuplicateAsset() {
			GetEquipment(this.duplicateUUID)
				.then((data) => {
					this.barcode = data.barcode;
					this.asset.name = data.name;
					this.asset.model = data.model;
					this.asset.group = data.group;
					this.asset.sub_group = data.sub_group;
					this.asset.id_number = data.id_number;
					this.asset.description = data.description;
					this.asset.contact = data.contact;
					this.asset.cost_price = data.cost_price;
					this.asset.vendor = data.supplier;
					this.asset.location = data.location;
					this.asset.image = data.image;
					this.asset.remark = data.remark;
					this.asset.purchased_on = data.purchased_on;
					this.asset.pico_barcode = data.pico_barcode;
					this.asset.password = data.password;
					this.asset.member = data.member;
					this.asset.is_fault = data.is_fault;
					this.asset.warranty_start_date = data.warranty_start_date;
					this.asset.warranty_end_date = data.warranty_end_date;
					this.asset.warranty_reminder_date = data.warranty_reminder_date;
					this.asset.maintenance_start_date = data.maintenance_start_date;
					this.asset.maintenance_end_date = data.maintenance_end_date;
					this.asset.maintenance_reminder_date = data.maintenance_reminder_date;
					this.asset.maintenance_by = data.maintenance_by;
					this.asset.maintenance_cost = data.maintenance_cost;

					this.$nextTick(() => {
						if (this.asset.group) {
							this.getSubGroup(this.asset.group);
						}
					});

					this.$store.dispatch(SET_BREADCRUMB, [
						{ text: "Asset", disabled: true },
						{ text: "Create", disabled: true },
					]);
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getCategories(data) {
			this.categoryList = data;
		},
		getBrands(data) {
			this.brandList = data;
		},
		filterTag(item, queryText, itemText) {
			if (item.header) return false;
			const hasValue = (val) => (val != null ? val : "");

			const text = hasValue(itemText);
			const query = hasValue(queryText);

			return text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) > -1;
		},
		initialize() {
			this.categoryList = this.localDB("groups", []);
			this.brandList = this.localDB("brands", []);

			this.vendorList = this.localDB("suppliers", []);

			this.reminder_type_items = this.localDB("reminder_types", []);
		},

	},
	computed: {
		...mapGetters(["errors", "localDB"]),
	},
	mounted() {
		this.initialize();
		this.getTags();

		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Asset", disabled: true },
			{ text: "Create", disabled: true },
		]);

		const { name, params, query } = this.$route;

		this.duplicateUUID = toString(query.duplicate);

		if (query && query.supplier) {
			this.asset.vendor = query.supplier;
		}
		if (query && query.group) {
			this.asset.group = query.group;
		}

		if (name === "equipment-update") {
			this.uuid = params.uuid;
			if (!this.uuid) {
				this.goBack();
			}
			this.pageLoading = true;
			this.getEquipment();
		} else {
			this.genrateBarcode("equipment").then((output) => {
				this.equipment.barcode = output;
			});
		}
		if (query && query.duplicate) {
			this.getEquipment(query.duplicate);
		} else if (this.duplicateUUID) {
			this.pageLoading = true;
			this.getDuplicateEquipment();
		}
	},
	beforeDestroy() {
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
	watch: {
		"equipment.tags": {
			deep: true,
			handler(val, prev) {
				if (val.length === prev.length) return;

				this.equipment.tags = val.map((v) => {
					if (typeof v === "string") {
						v = {
							tag: v,
						};
						this.items.push(v);
					}

					return v;
				});
			},
		},
	},
};
</script>
